<template>

  <div class="mt-5 d-flex justify-content-center">
    <div class="uncertainty-wrapper">
      <div class="uncertainty-image"
           v-bind:style="{ 'background-image': 'url(/static/uncertanty-bg.png'}"
           v-if="locale === 'bg'">
      </div>
      <div class="uncertainty-image" v-else></div>
    </div>
  </div>
</template>

<script setup>
import {useI18n} from "vue-i18n";
const { locale } = useI18n();
</script>

<style lang="scss" scoped>


.uncertainty-image {
  max-width: 100%;
  height: 400px;
  background: url('~@/assets/images/uncertanty.png') center center no-repeat;
  background-size: contain;

  @media (max-width: 992px) {
    height: 200px;
  }
  @media (max-width: 692px) {
    height: 150px;
  }
}
.uncertainty-wrapper {
  width: 100%!important;
}

</style>