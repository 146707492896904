<template>
  <div class="primary-hero row mt-5 p-5">
    <div class="col-md-12 title mb-5">{{ $t('statistics-hero-title') }}</div>
    <div class="col-md-4 stat">
      <img src="/static/stat-2-bg.png" alt="" v-if="locale === 'bg'"/>
      <img src="/static/stat-2.png" alt="" v-else/>
    </div>
    <div class="col-md-4 stat">
      <img src="/static/stat-1-bg.png" alt="" v-if="locale === 'bg'"/>
      <img src="/static/stat-1.png" alt="" v-else/>
    </div>
    <div class="col-md-4 stat">
      <img src="/static/stat-3-bg.png" alt="" v-if="locale === 'bg'"/>
      <img src="/static/stat-3.png" alt="" v-else/>
    </div>
    <div class="col-md-12 d-flex justify-content-center mt-4">
      <div class="bullet-points">
        <div>{{ $t('statistics-bullet-point-1') }}</div>
        <div>{{ $t('statistics-bullet-point-2') }}</div>
        <div>{{ $t('statistics-bullet-point-3') }}</div>
        <div>{{ $t('statistics-bullet-point-4') }}</div>
        <div>{{ $t('statistics-bullet-point-5') }}</div>
      </div>
    </div>
  </div>
</template>


<script setup>
import {useI18n} from "vue-i18n";
const { locale } = useI18n();
</script>

<style lang="scss" scoped>

.primary-hero {
  background: $color-pink-light;
  color: $color-purple;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  overflow: hidden;
}

.stat img {
  width: 100%;
}

.bullet-points {
  font-weight: 100;
  width: 80%;
  font-size: 14px;
  
  div {
    text-align: center;
    &:before{
      content:'\00b7';
      font-size:14px;
      line-height: 1;
    }
  }
}

.title {
  font-size: 25px;
  font-weight: 400;
  line-height: 1;
  color: #6B6B9C;
  text-align: center;
}
</style>