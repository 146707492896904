<template>
  <div class="wrapper">
    <div class="container d-none d-lg-block">
      <div class="row px-5">
        <div class="col-md-6 col-lg-3" v-for="(item, index) in items" v-bind:key="'num' + item">
          <div class="num p-4">0{{ index + 1 }}</div>
        </div>
      </div>
    </div>
    <div class="line d-none d-lg-block">
      <div class="container">
        <div class="row px-5">
          <div class="col-md-6 col-lg-3" v-for="(item) in items" v-bind:key="'point' + item">
            <div class="point ms-5"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row px-5">
        <div class="col-md-6 col-lg-3" v-for="(item, index) in items" v-bind:key="'item' + item">
          <div class="item mb-4 mb-lg-0 p-3">
            <div class="num d-md-none p-4">0{{ index + 1 }}</div>
            <div class="title mb-4">{{ item.title[locale] }}</div>
            <div class="content mb-5">{{ item.content[locale] }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="ornament-right"></div>
    <div class="ornament-left"></div>

  </div>

</template>

<script setup>
import { reactive } from 'vue';
import {useI18n} from "vue-i18n";
const { locale } = useI18n();

const items = reactive([
  {
    title: {
      en: 'Trustworthy validated data ',
      bg: 'Надеждни и валидирани данни'
    },
    content: {
      en: 'Our AI assistant is powered by reliable, research-based information verified by experts, ensuring you get the best advice.',
      bg: 'Нашият виртуален асистент е обучен с информация, проверена от експерти, което гарантира достоверността на предоставените ресурси за самопомощ.'
    },
  },
  {
    title: {
      en: 'Personalized to your own needs ',
      bg: 'Персонализирано според вашите нужди'
    },
    content: {
      en: 'Share information about yourself to receive a personalized experience. Your personal information is completely confidential.',
      bg: 'Споделете информация за себе си, за да получите персонализирано изживяване. Личната ви информация е напълно поверителна.'
    },
  },
  {
    title: {
      en: 'Conversational support anytime',
      bg: 'Разговорна подкрепа по всяко време'
    },
    content: {
      en: 'Ask questions, share how you feel, and receive tailored support with facts, coping strategies, and guidance to help you.',
      bg: 'Задавайте въпроси, споделяйте как се чувствате и получавайте подкрепа чрез факти, стратегии за справяне и насоки, които да ви помогнат'
    },
  },
  {
    title: {
      en: 'Expert help when needed',
      bg: 'Експертна помощ при нужда'
    },
    content: {
      en: 'If the assistant cannot help or screening indicates a need, you will be directed to a specialist.',
      bg: 'Ако асистентът не може да помогне или скринингът покаже необходимост, ще ви насочи към специалист.'
    },
  },
]);
</script>

<style lang="scss" scoped>
.wrapper {
  color: $color-purple;
  position: relative;
  background: #FFEFF2;
  padding: 80px 0;
}

.item {
  background: #fff;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 390px;

  @media (min-width: 768px) {
    height: 200px;
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    height: 280px;
  }

  @media (min-width: 1200px) {
    height: 280px;
  }

  .title {
    font-weight: 500;
  }

  .content {
    font-size: 13px;
  }
}

.num {
  background: #fff;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  font-size: 90px;
  font-family: $font-thin;
  font-weight: 100;
  color: $color-pink-light;
}

.line {
  position: relative;
  border-bottom: 1px solid $color-pink-dark;

  .point {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: $color-purple;
    top: -4px;
  }
}

.ornament-right,
.ornament-left {
  position: absolute;
  bottom: 0;
  z-index: 0;
  width: 30%;
  height: 50%;
}

.ornament-right {
  background: url('~@/assets/images/plant.png') no-repeat;
  background-size: contain;
  background-position: right bottom;
  right: 0;
}

.ornament-left {
  background: url('~@/assets/images/leaf.png') no-repeat;
  background-size: contain;
  background-position: left bottom;
  left: 0;
}

.container {
  position: relative;
  z-index: 999;
}
</style>