export default {
    "navigation": "Навигация",
    "slogan-line-1": "Следродилна психологическа",
    "slogan-line-2": "грижа от бъдещето",
    "home-heading-title": "Достъпна психологическа грижа за всяка майка",
    "home-subheading": "Премахваме стигмата около следродилната депресия и насърчаваме търсенето на помощ",
    "home-description": "Встъпването в ролята на майка е вълнуващо, но може да бъде и предизвикателно. Ние сме тук, за да предоставяме необходимата подкрепа и информация по теми, свързани с психичното здраве, грижата за себе си, родителството и много други.",
    "home-subtitle": "Бъдете сред първите от общността на Momfident",
    "home-signup-button": "Регистрирайте се",
    "statistics-hero-title": "Не сте сами",
    "statistics-bullet-point-1": "Традиционната здравна система се фокусира върху физическото възстановяване, често пренебрегвайки психичното и емоционално благополучие.",
    "statistics-bullet-point-2": "Семейният и социален натиск могат да ви оставят с усещане за прегаряне.",
    "statistics-bullet-point-3": "Объркани сте кога и къде да потърсите помощ. Много майки търсят помощ, когато проблемите са вече критични.",
    "statistics-bullet-point-4": "Традиционната терапия може да бъде скъпа, недостъпна и да изисква значително ангажиране.",
    "statistics-bullet-point-5": "Форумите и социалните мрежи често предоставят противоречиви и ненадеждни съвети, които могат дори да навредят.",
    "offering-title": "24/7 Следродилна превантивна помощ",
    "offering-description-1": "Запознайте се с Momfident, вашият виртуален психологически асистент, създаден да ви предоставя необходимата подкрепа. Обучен на основата на академични изследвания и най-добрите експертни практики, Momfident предлага научно базирана информация и ресурси за самопомощ, насочени към майките през първите три години след раждането.",
    "offering-description-2": "Независимо дали се сблъсквате с предизвикателства, свързани с грижата за себе си, връзката с детето ви, емоционалното ви състояние или родителството, ние сме тук, за да ви помогнем. Нашият виртуален асистент е на разположение по всяко време, взимайки предвид вашата лична информация и предоставяйки полезни ресурси: рефлективни въпроси, стратегии за справяне, успешни примери от други майки, както и ранни скрийнинги, които да ви насочат кога да потърсите специализирана помощ. Бъдете уверени в грижата за себе си, знаейки, че Momfident е винаги до вас.",
    "community-title-1": "Присъединете се към",
    "community-title-2": "общността на Momfident",
    "community-description": "Получете ранен достъп до нашия AI асистент. Помогнете ни да създадем пространство за заслужена емоционална и психологическа подкрепа, като се регистрирате за ранен достъп и участвате в нашето анкетно проучване.",
    "community-signup-button": "Регистрация",
    "benefits-title-1": "Интегрирана психологическа грижа",
    "benefits-heading": "Как помагаме на специалистите",
    "benefits-content-1-1": "Предоставяне на своевременна превантивна психологическа грижа, която помага за намаляване на тревожността.",
    "benefits-content-1-2": "Повишаване на ефективността, като пациентите са по-добре информирани, което позволява на специалистите да се съсредоточат върху въпроси, изискващи индивидуален подход",

    "benefits-title-2": "Своевременна ангажираност и подкрепа",
    "benefits-content-2-1": "Повишете ангажираността чрез скрийнинг въпроси, които откриват нуждата от намеса на специалист.",
    "benefits-content-2-2": "Разширете подкрепата чрез интегриране на допълнителни здравни и уелнес теми, свързани с благополучието на семейството.",
    "benefits-title-3": "Възможности за бизнес растеж",
    "benefits-content-3-1": "Станете сертифициран специалист в неразвита ниша за подкрепа на жени в следродилния период.",
    "benefits-content-3-2": "Използвайте лицензираната версия или я ребрандирайте за вашата организация, за да подобрите услугите си и задържите пациентите.",
    "benefits-title-4": "Персонализирана грижа, основана на данни",
    "benefits-content-4-1": "Интегрирайте собствените си здравни данни, за да осигурите цялостна здравна подкрепа, съобразена с индивидуалните нужди на всеки пациент.",
    "benefits-content-4-2": "Получавайте полезни прозрения и подобрявайте грижата чрез вземане на решения, базирани на данни.",
    "unique-heading": "Майчинството може да бъде всичко това",
    "unique-title": "Какво ни прави",
    "unique-span": "уникални?",
    "unique-item-1-title": "Лесно достъпна психологическа грижа",
    "unique-item-1-content": "Предлагаме достъпна и ефективна подкрепа за психичното здраве и благополучие, насочена към майки във вълнуващия, но и критичен следродилен период.",
    "unique-item-2-title": "Подкрепа, базирана на изследвания и AI",
    "unique-item-2-content": "Модел, обучен с научни данни и експертни практики, осигурява 24/7 персонализирана подкрепа чрез разговор.",
    "unique-item-3-title": "Следродилна психологическа грижа от бъдещето",
    "unique-item-3-content": "Подобряване ефективността на специалистите с инструменти за ранен скрининг, проследяване и ангажираност. Премиум грижа.",
    "form-first-name": "Име:",
    "form-last-name": "Фамилия:",
    "form-country": "Държава:",
    "form-email": "Email:",
    "form-phone": "Телефоон:",
    "form-tnc-agreement": "Съгласявам се с",
    "form-tnc-link": "общите условия",
    "form-subscribe": "Регистрация",
    "form-subscribe-description": "Абонирайте се сега за ранен достъп до нашия виртуален асистент. Ще имате възможност да участвате в нашето проучване, което ще ни помогне да съберем национални данни за това как жените преминават през следродилния период — предизвикателствата, пред които се изправят, и ефективните стратегии.",
    "form-subscribe-success": "Благодарим ви, че се присъединихте! Помогнете ни да създадем по-добра подкрепа за новите майки, като споделите своя опит. Попълнете нашето проучване – вашият глас има значение!",
    "take-our-survey": "Попълнете нашето проучване",
    "follow-us": "Последвайте ни"
}
