<template>

  <div class="row p-2 p-md-5 wrapper">
    <div class="top-bar"></div>
    <div class="col-md-7">
      <div class="title pt-5 mb-5">{{ $t('offering-title') }}</div>
      <div class="image pt-5 pt-md-4" v-if="locale === 'bg'"><img src="/static/offering-scheme-bg.png" /></div>
      <div class="image p5-5 pt-md-4" v-else><img src="/static/offering-scheme.png" /></div>
      <div class="content mt-5">
        <p class="mb-2">
          {{ $t('offering-description-1') }}
        </p>
        <p>
          {{ $t('offering-description-2') }}
        </p>
      </div>
    </div>
    <div class="col-md-5">
      <div class="side-image"></div>
    </div>
  </div>

</template>


<script setup>
import {useI18n} from "vue-i18n";
const { locale } = useI18n();
</script>

<style lang="scss" scoped>
.wrapper {
  color: $color-purple;
  position: relative;
}

.top-bar {
  height: 200px;
  width: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  background: $color-pink-light;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.image img {
  width: 70%;
  @media (max-width: 992px) {
    width: 100%;
  }
}

.side-image {
  max-width: 100%;
  height: 600px;
  background: url('~@/assets/images/phone-proto.png') center center no-repeat;
  background-size: contain;
}

.title {
  font-size: 36px;
  font-weight: 500;
  line-height: 1;
  height: 120px;
  font-family: $font-regular;

  @media (max-width: 992px) {
    font-size: 28px;
  }
}

.content {
  font-size: 13px;
}

</style>