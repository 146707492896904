export default {
    "navigation": "Навигация",
    "slogan-line-1": "Building the future of",
    "slogan-line-2": "virtual postpartum care",
    "home-heading-title": "Maternal care made accessible",
    "home-subheading": "Breaking the stigma around postpartum mental health",
    "home-description": "Navigating motherhood can be overwhelming. We're here to bridge the gap between healthcare professionals and mothers, providing the support and information you need on topics related to mental health, self-care, parenting and more",
    "home-subtitle": "Be the first to experience Momfident",
    "home-signup-button": "Sign Up",
    "statistics-hero-title": "You are not alone",
    "statistics-bullet-point-1": "Traditional healthcare focuses on physical recovery, often neglecting your mental and emotional well-being.",
    "statistics-bullet-point-2": "Family and societal pressures can leave you feeling overwhelmed.",
    "statistics-bullet-point-3": "You might not know when or where to seek help. Many moms wait until issues are critical.",
    "statistics-bullet-point-4": "Traditional therapy can be costly, inaccessible, and demands significant commitment.",
    "statistics-bullet-point-5": "Forums and social media can provide conflicting and unreliable advice, often causing more harm.",
    "offering-title": "Offering psycho-education and preventative help",
    "offering-description-1": "Meet Momfident, your AI-powered assistant designed to provide the support you need. Built on solid academic research and expert best practices, Momfident offers evidence-based information and resources tailored for moms in the first 36 months postpartum.",
    "offering-description-2": "Whether you’re facing self-care, mental health, or parenting challenges, we’re here to help. Our virtual assistant delivers personalized conversations, practical coping strategies, and even pre-screening check-ups to guide you on when to seek specialized help. Commit to self-care with confidence, knowing Momfident is by your side.",
    "community-title-1": "Join the",
    "community-title-2": "Momfident Community",
    "community-description": "Get early access to our AI-powered virtual assistant. Help us improve by providing feedback and receive the psychological support you deserve.",
    "community-signup-button": "Sign Up",
    "benefits-title-1": "Efficient Patient Management",
    "benefits-heading": "What do you get as a health practitioner",
    "benefits-content-1-1": "Provide preventative care through ongoing screening and real-time monitoring.",
    "benefits-content-1-2": "Save time with already educated patients and access to comprehensive patient records.",

    "benefits-title-2": "Continuous engagement and support",
    "benefits-content-2-1": "Improve engagement through personalized education and ongoing evidence-based support.",
    "benefits-content-2-2": "Extend support across major stages of women's health including fertility, pregnancy and in the future menopause.",

    "benefits-title-3": "Business growth opportunities",
    "benefits-content-3-1": "Be discovered and specialize in an untapped niche as a certified specialist.",
    "benefits-content-3-2": "Use as a white-label solution or rebrand for your organization to facilitate cross-selling and upselling.",

    "benefits-title-4": "Data-driven personalized care",
    "benefits-content-4-1": "Integrate your own health data to provide holistic health support to individual patient needs.",
    "benefits-content-4-2": "Gain insights and improve care through data-driven decisions.",
    "unique-heading": "Motherhood can be all of this",
    "unique-title": "What makes us",
    "unique-span": "unique?",

    "unique-item-1-title": "Affordable High-Impact Care",
    "unique-item-1-content": "Offers accessible, affordable mental health and well-being support tailored for new mothers during the critical postpartum period.",

    "unique-item-2-title": "AI-Enhanced Evidence-Based Support",
    "unique-item-2-content": "Leverages scientifically validated data, enhanced with AI to deliver personalized, understandable evidence based resources.",

    "unique-item-3-title": "Next-Level Postpartum Support",
    "unique-item-3-content": "Improves preventative care efficiency and patient outcomes with tools for early diagnosis, patient engagement, and monitoring, while enabling better quality healthcare.",
    "form-first-name": "First Name:",
    "form-last-name": "Last Name:",
    "form-country": "Country:",
    "form-email": "Email:",
    "form-phone": "Phone:",
    "form-tnc-agreement": "By signing up, you agree to our",
    "form-tnc-link": "Terms and Conditions",
    "form-subscribe": "Subscribe",
    "form-subscribe-description": "Subscribe now for early access to our virtual assistant. You'll have the opportunity to participate in our survey, helping us collect national data on how women navigate the postpartum period—the challenges they face and effective strategies.",
    "form-subscribe-success": " Thank you for joining! Help shape support for new moms by sharing your experience. Complete our survey – your voice makes a difference!",
    "take-our-survey": "Complete our survey",
    "follow-us": "Follow us"
}