<template>
  <div class="d-flex justify-content-between my-4">
    <div class="title d-none d-md-block">
      <div>{{ $t('slogan-line-1') }}</div>
      <div>{{ $t('slogan-line-2') }}</div>
    </div>
    <div class="logo">
      <router-link :to="{name: 'home'}"><img src="/static/logo.png" /></router-link>
    </div>
    <div class="d-flex">
      <MainButton @click="$router.push({name: 'subscribe'})">{{ $t('home-signup-button') }}</MainButton>
      <a href="/" class="lang-btn" v-if="locale === 'bg'" @click="setLanguage('en')">EN</a>
      <a href="/bg" class="lang-btn" v-if="locale === 'en'" @click="setLanguage('bg')">БГ</a>
    </div>
  </div>

</template>

<script setup>
import MainButton from '@/components/Common/Buttons/MainButton.vue';
import {useI18n} from "vue-i18n";
const { locale } = useI18n();
function setLanguage() {
  // alert(lang);;
}
</script>

<style lang="scss" scoped>
.lang-btn {
  padding: 10px 12px;
  border: 1px solid;
  border-radius: 30px;
  font-size: 11px;
  margin-left: 10px;
  cursor: pointer;
}
.logo {
  width: 160px;
  img {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 140px;
    margin-top: 10px;
  }
}
.title {
  font-size: 12px;
  color: $color-purple;
}
</style>