<template>
  <div class="wrapper">
    <div class="row">
      <div class="col-md-6 col-lg-3">
        <div class="item">
          <div class="image mb-3">
            <img src="/static/icon-checklist.png" alt="" />
          </div>
          <div class="title mb-3">{{ $t('benefits-title-1') }}</div>
          <div class="content">
            <p>{{ $t('benefits-content-1-1') }}</p>
            <p>{{ $t('benefits-content-1-2') }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 mb-5">
        <div class="item">
          <div class="image mb-3">
            <img src="/static/icon-heart.png" alt="" />
          </div>
          <div class="title mb-3">{{ $t('benefits-title-2') }}</div>
          <div class="content">
            <p>{{ $t('benefits-content-2-1') }}</p>
            <p>{{ $t('benefits-content-2-2') }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="item">
          <div class="image mb-3">
            <img src="/static/icon-items.png" alt="" />
          </div>
          <div class="title mb-3">{{ $t('benefits-title-3') }}</div>
          <div class="content">
            <p>{{ $t('benefits-content-3-1') }}</p>
            <p>{{ $t('benefits-content-3-2') }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="item">
          <div class="image mb-3">
            <img src="/static/icon-smart.png" alt="" />
          </div>
          <div class="title mb-3">{{ $t('benefits-title-4') }}</div>
          <div class="content">
            <p>{{ $t('benefits-content-4-1') }}</p>
            <p>{{ $t('benefits-content-4-2') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
</script>

<style lang="scss" scoped>
.wrapper {
  color: $color-purple;
}

.image {
  text-align: center;

  img {
    width: 135px;
  }
}

.item {
  padding: 0 50px;
  @media (max-width: 992px) {
    margin-bottom: 70px;
  }
}

.title {
  font-family: $font-semi-bold;
  font-size: 14px;
  text-align: center;

}

.content {
  font-family: $font-thin;
  font-size: 13px;

  @media (max-width: 992px) {
    text-align: center;
  }
}

</style>