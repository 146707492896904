<template>
  <div class="container">
    <NavigationBar/>
    <div class="title">{{ $t('form-subscribe') }}</div>
    <div class=" d-flex justify-content-center">
      <div class="wrapper">
        <div class="mb-4" v-if="!sent">{{ $t('form-subscribe-description') }}</div>
        <div v-if="sent" class="text-success p-2 w-100 text-center">
          <div class="mb-4">
            {{ $t('form-subscribe-success') }}
          </div>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSfVTOL0GOjJl3_OvfWYTfyS6wU31Smm8ezWuHggel15etrDJg/viewform">
            <MainButton @click="sendForm">{{ $t('take-our-survey') }}</MainButton>
          </a>
        </div>
        <div v-if="err" class="text-danger p-2 w-100 text-center">Error! Please try again later.</div>
        <div v-else-if="!sent">
          <div class="mb-2">
            <label>{{ $t('form-first-name') }}</label>
          </div>
          <div class="mb-2">
            <input type="text" v-model="firstName" :class="{'form-error': formErrors.includes('firstName')}">
          </div>

          <div class="mb-2">
            <label>{{ $t('form-last-name') }}</label>
          </div>
          <div class="mb-2">
            <input type="text" v-model="lastName" :class="{'form-error': formErrors.includes('lastName')}">
          </div>

          <div class="mb-2">
            <label>{{ $t('form-country') }}</label>
          </div>
          <div class="mb-2">
            <input type="text" v-model="country">
          </div>

          <div class="mb-2">
            <label>{{ $t('form-email') }}</label>
          </div>
          <div class="mb-2">
            <input type="text" v-model="email" :class="{'form-error': formErrors.includes('email')}">
          </div>

          <div class="mb-2">
            <label>{{ $t('form-phone') }}</label>
          </div>
          <div class="mb-2">
            <input type="text" v-model="phone">
          </div>

          <div class="mt-4 d-flex tnc-box">
            <div>
              <input type="checkbox" v-model="tnc">
            </div>
            <div v-bind:class="{'error': formErrors.includes('tnc')}">
              {{ $t('form-tnc-agreement') }}
              <router-link :to="{name: 'tnc'}">{{ $t('form-tnc-link') }}</router-link>
            </div>
          </div>

          <div class="mt-4 d-flex justify-content-center">
            <MainButton @click="sendForm">{{ $t('form-subscribe') }}</MainButton>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  max-width: 600px;
  color: $color-purple;
}
.title {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
  color: $color-purple;
}
label {
  font-size: 14px;
  color: $color-purple;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-error {
  border: 1px solid #a62a2a;
  background: #fffafa;
}

.tnc-box {
  font-size: 12px;

  input {
    width: 25px;
  }

  .error, a:link, a:hover, a:active, a:visited {
    color: #a62a2a;
  }
}
</style>
<script setup>
import MainButton from '@/components/Common/Buttons/MainButton';
import {ref} from 'vue';
import NavigationBar from "@/components/NavigationBar.vue";
import axios from "axios";

const formErrors = ref([])

const firstName = ref(null)
const lastName = ref(null)
const country = ref(null)
const email = ref(null)
const phone = ref(null)
const tnc = ref(null)
const sent = ref(false)
const err = ref(false)

async function sendForm() {
  try {
    sent.value = false;
    err.value = false;
    formErrors.value = [];

    if (tnc.value !== true) {
      formErrors.value.push('tnc')
    }

    if (firstName.value === null || firstName.value.length < 2) {
      formErrors.value.push('firstName')
    }

    if (lastName.value === null || lastName.value.length < 2) {
      formErrors.value.push('lastName')
    }

    const mailRegex = /\S+@\S+\.\S+/;
    if (email.value === null || email.value === '' || !mailRegex.test(email.value)) {
      formErrors.value.push('email');
    }

    if (formErrors.value.length > 0) {
      return Promise.resolve();
    }

    await axios.post('/api/subscribe', {
      firstName: firstName.value,
      lastName: lastName.value,
      country: country.value,
      phone: phone.value,
      email: email.value,
    })

    formErrors.value = [];
    sent.value = true
  } catch (err) {
    console.log(err);
    err.value = true
  }
}
</script>
